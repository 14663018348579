<template>
  <div>
    <CreateCollection/>
  </div>
</template>

<script>
import CreateCollection from "../../components/collection/CreateCollection.vue";
import { confirmBox } from "../../utils/confirmBox";

export default {
  components: { CreateCollection },
};
</script>
